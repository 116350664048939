import "@fontsource/figtree/400.css";
import "@fontsource/figtree/500.css";
import "@fontsource/figtree/600.css";
import "@fontsource-variable/inter";

import "./src/styles/global.scss";

export const onInitialClientRender = (): void => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    process.env.GATSBY_WIDGET_SCRIPT_URL ||
    "https://assets.chatwithcharley.org/snippet.js";
  document.body.appendChild(script);
};
